import * as React from "react";
import {useEffect} from "react";
import {getImage} from "gatsby-plugin-image"
import {graphql, PageProps} from "gatsby";
import Cookies from "universal-cookie";

import CleanDataParser from "../helpers/editorjs-parser";
import Layout from "../components/layout";
import TemplateHead from "../components/template-head";
import Hero from "../components/hero";
import HeroContentHeading from "../components/hero-content-heading";
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import Block4Image8Text from "../components/blocks/block-4image-8text";
import Section from "../components/section";
import StepSection from "../components/step-section";
import ServiceTabs from "../components/tab/services-tabs";
import BenefitsSliderServices from "../components/slider/benefits-slider-services";

import "../styles/page-services.scss";

const cookies = new Cookies();

type DataProps = {
  strapiAdvisor: any,
  cremationPageData: any,
  strapiCity: any
}

export default function CityDirectCremation({
                                              data: {
                                                strapiCity,
                                                strapiAdvisor,
                                                cremationPageData
                                              }
                                            }: PageProps<DataProps>) {

  const imageSimple = getImage(cremationPageData?.service?.simpleImage?.localFile)
  const advisor = strapiAdvisor
  const city = strapiCity;

  useEffect(() => {
    let d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    let expiresTime = d;
    if (city.isHub) {
      const cookieHub = cookies.get("fenix4hub");
      if (cookieHub !== city.cityUrl) {
        cookies.remove("fenix4hub", {path: '/'});
        cookies.set("fenix4hub", city.cityUrl, {
          expires: expiresTime,
          path: "/",
        });
        cookies.set("fenix4label", city.title, {
          expires: expiresTime,
          path: "/",
        });
      }
    } else {
      const cookieCity = cookies.get("fenix4city");
      if (cookieCity !== city.cityUrl) {
        cookies.remove("fenix4city", {path: '/'});
        cookies.remove("fenix4hub", {path: '/'});
        cookies.set("fenix4city", city.cityUrl, {
          expires: expiresTime,
          path: "/",
        });
        cookies.set("fenix4label", city.title, {
          expires: expiresTime,
          path: "/",
        });
        cookies.set("fenix4hub", city.hub.urlPart, {
          expires: expiresTime,
          path: "/",
        });
      }
    }

  }, [city]);

  const priceText = (
    <div className="price-text mt-2 text-white">
      {cremationPageData?.service?.priceText?.data?.priceText ? (
        JSON.parse(cremationPageData?.service?.priceText?.data?.priceText).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  const textSimple = (
    <div className="">
      {cremationPageData?.service?.textSimple?.data?.textSimple ? (
        JSON.parse(cremationPageData?.service?.textSimple?.data?.textSimple).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  const textHowItWorks = (
    <div className="">
      {cremationPageData?.service?.textHowItWorks?.data?.textHowItWorks ? (
        JSON.parse(cremationPageData?.service?.textHowItWorks?.data?.textHowItWorks).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--funeral hero-section--funeral-city pl-4 pr-4"
        bgImageClassName="hero-image hero-image--funeral"
        contentClassName="hero-content hero-content--funeral"
        altImages="Direct Cremation Fenix Funeral Directors"
        imageGlow={"/images/glow.png"}
        breadcrumb={[
          {name: "Fenix Home", slug: "/"},
          {name: "Direct Cremation in " + city.title, slug: "/direct-cremation/"},
        ]}
      >
        <HeroContentHeading
          headingTitle={cremationPageData?.service?.title + " in " + city.title}
          headingContent={cremationPageData?.service?.subtitle}
          price={cremationPageData?.service?.priceCurrency + " " + cremationPageData?.service?.price}
          priceList={priceText}
          advisorData={advisor}
          citeText=""
        />
      </Hero>
      <Section>
        <Block4Image8Text
          rowClass="row no-gutters d-flex flex-column flex-md-row "
          col1Class="col-12 col-md-4 col-top-left"
          col2Class="col-12 col-md-8 col-bottom-right bg-white pl-4 pl-md-5 pr-4 pr-md-5 pt-4 pt-md-5 pb-4 pb-md-5 col-text"
          imageOptimized={imageSimple}
          h2={cremationPageData?.service?.titleSimple}
          text={textSimple}
        />
      </Section>
      <Section
        sectionClass={
          "bg-deepblue pl-0 pl-md-4 pr-0 pr-md-4 pt-4 section-container section-container--tabs"
        }
      >
        <ServiceTabs
          includedServices={cremationPageData?.service?.tab}
        />
        <img
          src="/images/stripe_1.svg"
          alt="Fenix Funeral Directors"
          className="stripe stripe--1 stripe--services d-none d-lg-block"
        />
      </Section>
      <BenefitsSliderServices
        benefitsData={cremationPageData?.service?.slider}
        sliderClass="bg-deepblue pl-0 pr-0 pl-sm-4 pr-sm-4 pt-5 pt-md-0 pb-5 service-benefits"
      />
      <StepSection
        stepData={cremationPageData?.service?.guidance}
        stepDataTitle2={cremationPageData?.service?.titleHowItWorks}
        stepDataSubtitle2={textHowItWorks}
        stepDataCta={"Begin planning a funeral"}
      />
      <TrustpilotCarousel/>
    </Layout>
  );
}

export function Head() {
  return (
    <TemplateHead
      title="Direct Cremation - Fenix Funeral Directors"
      shortlink="https://fenixfuneral.co.uk/direct-cremation/"
      alternate="https://fenixfuneral.co.uk/direct-cremation/"
      canonical="https://fenixfuneral.co.uk/direct-cremation/"
      og={{
        url: "https://fenixfuneral.co.uk/direct-cremation/",
        title: "Direct Cremation - Fenix Funeral Directors",
      }}
      ldjsonbreadcrumb={`{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement":
        [{
        "@type": "ListItem",
        "position": "1",
        "name": "Fenix Home","item": "https://fenixfuneral.co.uk/"
        },
        {
        "@type": "ListItem",
        "position": "2",
        "name": "Direct Cremation","item": "https://fenixfuneral.co.uk/direct-cremation/"
        }]
        }`
      }
    />
  );
}

export const pageQuery = graphql`
    query ($slug: String) {
        strapiCity(cityUrl: { eq: $slug }) {
            id
            strapi_id
            title
            cityUrl
            isHub
            hub {
                id
                title
                urlPart
            }
        }
        cremationPageData: strapiServiceDirectCremation {
            service {
                title
                titleHowItWorks
                textHowItWorks {
                    data {
                        textHowItWorks
                    }
                }
                titleSimple
                textSimple {
                    data {
                        textSimple
                    }
                }
                simpleImage {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: NONE,
                                outputPixelDensities: 1
                            )
                        }
                    }
                }
                tab {
                    title
                    text {
                        data {
                            text
                        }
                    }
                }
                subtitle
                slug
                priceCurrency
                price
                priceText {
                    data {
                        priceText
                    }
                }
                slider {
                    title
                    textMain
                    icon {
                        url
                    }
                }
                guidance {
                    titleNumber
                    textGuide {
                        data {
                            textGuide
                        }
                    }
                }
            }
        }
        strapiAdvisor(url: {eq: "emily-cross"}) {
            id
            title
            url
            fullname
            description {
                data {
                    description
                }
            }
            photo2x {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 400, 
                            height: 400, 
                            placeholder: NONE,
                            outputPixelDensities: 1
                        )
                    }
                }
            }
        }
    }
`;